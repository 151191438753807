import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout';
import PlainPage from 'components/PlainPage';
import { useIntl } from 'gatsby-plugin-react-intl';
import SEO from 'components/seo';
import get from 'lodash.get';

export default function DataProtectionPage(props) {
    let {
        data: { contentfulPage },
    } = props;

    let intl = useIntl();
    const content =
        '<section class="Main-content" data-content-field="main-content">' +
        intl.formatMessage({ id: 'data-protection-text-1' }) +
        intl.formatMessage({ id: 'data-protection-text-2' }) +
        intl.formatMessage({ id: 'data-protection-text-3' }) +
        intl.formatMessage({ id: 'data-protection-text-4' }) +
        intl.formatMessage({ id: 'data-protection-text-5' }) +
        intl.formatMessage({ id: 'data-protection-text-6' }) +
        intl.formatMessage({ id: 'data-protection-text-7' }) +
        intl.formatMessage({ id: 'data-protection-text-8' }) +
        '</div></div></div></div></div>' +
        '</section>';

    return (
        <Layout>
            <SEO
                description={get(
                    contentfulPage,
                    'seoListing.description.description'
                )}
                title={get(contentfulPage, 'seoListing.seoTitle')}
                slug={'data-protection'}
            />
            <PlainPage content={content} />
        </Layout>
    );
}

export const query = graphql`
    query($locale: String) {
        contentfulPage(
            title: { eq: "Data Protection" }
            node_locale: { eq: $locale }
        ) {
            ...ContentfulPageFields
        }
    }
`;
